// Flag keys
export const FLAGS = Object.freeze({
  CAPSULE_CREATE_PAGE: "capsule-create-page",
  CAPSULE_SHARING_PAGE: "capsule-sharing-page",
  CONTENT_REPARENTING: "content-reparenting",
  EDITOR_AUDIO: "editor-audio-upload",
  EDITOR_YOUTUBE: "editor-youtube",
  MY_WORLD_PAGE: "my-world-page",
  ONBOARDING: "onboarding",
  PAYMENT: "payment",
  SLATE_EDITOR_OPT_IN: "slate-editor-opt-in",
  SLATE_EDITOR_NEW_STORY_DEFAULT: "slate-editor-new-story-default",
  STORY_ANALYTICS: "story-analytics",
  STORY_COMMENTS: "story-comments",
  STORY_COVER_IMAGE: "story-cover-image",
  STORY_COVER_MEDIA_PICKER: "story-cover-media-picker",
  STORY_COVER: "story-cover",
  STORY_MEDIA: "story-media",
  STORY_TEMPLATES: "story-templates",
  UNIFIED_DASHBOARD: "unified-dashboard",
  WORLD_PLAYER: "world-player",
  WORLD_SHARING_PAGE: "world-sharing-page",
  WORLDS_EXPERIMENTAL: "worlds-experimental",
});

export const ALL_FLAGS = Object.values(FLAGS);
