import Axios from "axios";
import { useQuery } from "react-query";

import useAuth from "./useAuth";

export function useFlag(flag, defaultValue = false) {
  const { currentUser } = useAuth();
  const { data: flags } = useQuery(
    ["user", currentUser?.id, "flags"],
    async () =>
      (
        await Axios.get(
          `/api/flags${currentUser ? `?key=${currentUser.id}` : ""}`
        )
      ).data,
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      staleTime: Infinity,
      cacheTime: Infinity,
    }
  );

  if (!flags || !(flag in flags)) {
    return defaultValue;
  }

  const flagValue = flags[flag];

  switch (flagValue) {
    case "on":
      return true;
    case "off":
      return false;
    case "control":
      return defaultValue;
    default:
      return flagValue;
  }
}
