import emojiRegex from "emoji-regex";

const regex = emojiRegex();

export function isEmoji(character) {
  return regex.test(character);
}

export function extractEmoji(text) {
  const textWithoutEmojis = Array.from(text)
    .filter((c) => !isEmoji(c))
    .join("");
  const emojis = Array.from(text).filter(isEmoji);

  return {
    text,
    textWithoutEmojis,
    emojis,
  };
}
